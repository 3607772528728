import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useModal from '../../../hooks/useModal/useModal';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { RootState } from '../../../stores/Store';
import { Header, Row } from '../../../components/Table/TableModels';
import { SuccessTickIconTable } from '../../../components/Icons/SuccessTickIcon';
import BulkImportDetailsRowEdit from './components/BulkImportDetailsRowEdit';
import { setBulkImportStep } from '../../../stores/ImportStore';
import { UploadType, BulkImportValidRowsRequest } from '../../../models/ImportModels';
import ImportService from '../../../services/Import/ImportService';

const simSwapHeaders = ['row', 'mobileNumber', 'endUserEmail', 'deviceEid', 'editInfo'];
const newConnectionsHeaders = ['row', 'endUserEmail', 'deviceEid', 'editInfo'];

function useBulkImportEditRows() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const snackBar = useSnackBar();
  const [tableHeaders, setTableHeaders] = useState(simSwapHeaders);
  const { importResponse, bulkImportErrors } = useSelector((state: RootState) => state.importReducer);
  const modal = useModal();

  /**
   * Shows a modal to inform the user that he cannot proceed to the next step
   */
  const showUserCannotProceedModal = () => {
    modal.showModal({
      modalTitle: t('pages.bulkImport.errorsTitle'),
      modalDescription: t('pages.bulkImport.errorsNoProceed'),
      confirmationModal: false,
      confirmationButtonLabel: t('common.cancel'),
      onAccept: (setOpen) => {
        setOpen(false);
      },
    });
  };

  /**
   * Fetch bulk import rows
   * @param uploadType
   * @param uploadId
   */
  const fetchBulkImportValidRows = (uploadType: UploadType, uploadId: string) => {
    const requestParams: BulkImportValidRowsRequest = {
      uploadId,
      uploadType,
      page: 0,
      pageSize: 10,
      sort: 'creationDate',
      sortingType: 'desc',
    };
    ImportService.api.fetchBulkImportValidRows(requestParams).then(
      (response) => {
        if (response.data.totalElements > 0) {
          dispatch(setBulkImportStep(2));
        } else {
          showUserCannotProceedModal();
        }
      },
      () => {
        snackBar.showSnackBar(t('pages.bulkImport.validRowsError'), 'error');
      },
    );
  };

  /**
   * Shows a modal to inform the user that rows with errors will be excluded
   */
  const showErrorsExcludedModal = () => {
    modal.showModal({
      modalTitle: t('pages.bulkImport.errorsTitle'),
      modalDescription: t('pages.bulkImport.errorsExcludedMessage'),
      confirmationModal: true,
      confirmationButtonLabel: t('common.proceed'),
      cancelButtonLabel: t('common.cancel'),
      onAccept: (setOpen) => {
        if (importResponse) {
          fetchBulkImportValidRows(importResponse.uploadType, importResponse.uploadId);
        }
        setOpen(false);
      },
    });
  };

  useEffect(() => {
    switch (importResponse?.uploadType) {
      case UploadType.SIM_SWAPS:
        setTableHeaders(simSwapHeaders);
        break;
      case UploadType.NEW_CONNECTIONS:
        setTableHeaders(newConnectionsHeaders);
        break;
      default:
        break;
    }
  }, [importResponse?.uploadType]);

  const headers: Header[] = useMemo(
    () =>
      tableHeaders.map((item) => ({
        id: item,
        label: t(`pages.bulkImport.table.editTable.headers.${item}`),
      })),
    [t, tableHeaders],
  );

  const rows: Row[] = useMemo(() => {
    if (bulkImportErrors) {
      return bulkImportErrors.map((error) => ({
        id: `row-${error.numberOfLine}`,
        cells: [
          {
            value: !error.validRecord ? (
              `${error.numberOfLine}`
            ) : (
              <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                {error.numberOfLine}
                <SuccessTickIconTable />
              </Box>
            ),
            hasError: !error.validRecord,
          },
          ...(importResponse?.uploadType === UploadType.SIM_SWAPS
            ? [
                {
                  value: error.phoneNumber.value,
                  hasError: error.phoneNumber.error,
                },
              ]
            : []),
          {
            value: error.userEmail.value,
            hasError: error.userEmail.error,
          },
          {
            value: error.eid.value,
            hasError: error.eid.error,
          },
          {
            value: <BulkImportDetailsRowEdit details={error} />,
          },
        ],
        hasErrors: !error.validRecord,
      }));
    }

    return [];
  }, [t, bulkImportErrors]);

  return { headers, rows, showErrorsExcludedModal };
}

export default useBulkImportEditRows;
