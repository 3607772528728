import { Box } from '@mui/material';
import React, { useState } from 'react';
import Drawer from '../../../../components/Drawer/Drawer';
import BulkImportNotificationsStep from './BulkImportNotificationsStep';
import BulkImportSummaryStep from './BulkImportSummaryStep';
import useBulkImportDrawer from './useBulkImportDrawer';

interface BulkImportDrawerProps {
  selectedBulkImports: number;
  open: boolean;
  onClose: () => void;
}
function BulkImportDrawer({ selectedBulkImports, open, onClose }: BulkImportDrawerProps) {
  /**
   * Handle the close action
   */
  const handleClose = () => {
    onClose();
  };
  const [step, setStep] = useState(1);
  const NUMBER_OF_STEPS = 2;
  const [progress, setProgress] = useState(100 / NUMBER_OF_STEPS);
  const { availableNotifications, isLoading, confirmBulkImport, drawerTitle, availableTariffs } =
    useBulkImportDrawer(handleClose);

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Next step increment
   */
  const nextStep = () => {
    setStep((prevState) => prevState + 1);
  };

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === 1) {
      handleClose();
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of the sim swap summary journey
   */
  const renderSteps = () => {
    switch (step) {
      case 1:
        return (
          <BulkImportNotificationsStep
            availableNotifications={availableNotifications}
            onClose={handleClose}
            nextStep={nextStep}
          />
        );
      case 2:
        return (
          <BulkImportSummaryStep
            selectedBulkImports={selectedBulkImports}
            onClose={onClose}
            nextStep={confirmBulkImport}
            isLoading={isLoading}
            availableTariffs={availableTariffs}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={drawerTitle}
      progress={progress}
    >
      <Box sx={{ paddingTop: '2rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default BulkImportDrawer;
