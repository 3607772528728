import React from 'react';
import { Breadcrumbs as MaterialBreadcrumbs, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '../Icons/ChevronRightIcon';
import ClickableLink from '../Text/ClickableLink';

interface Link {
  label: string;
  url: string;
  onClick?: () => void;
}
interface BreadcrumbsProps {
  activeLinks: Link[];
  disabledLinks: string[];
}
function Breadcrumbs({ activeLinks, disabledLinks }: BreadcrumbsProps) {
  return (
    <MaterialBreadcrumbs
      aria-label="breadcrumb"
      separator={<ChevronRightIcon stroke="#333" />}
      sx={{ paddingBottom: '1.5rem' }}
    >
      {activeLinks.map((link) => (
        <ClickableLink key={link.label} to={link.url} onClick={link.onClick} sx={{ textDecoration: 'none' }}>
          {link.label ? link.label : <Skeleton width="100px" />}
        </ClickableLink>
      ))}
      {disabledLinks.map((link) => (
        <Typography sx={{ textDecoration: link ? 'underline' : 'none', cursor: 'default' }} key={link}>
          {link || <Skeleton width="100px" />}
        </Typography>
      ))}
    </MaterialBreadcrumbs>
  );
}

export default Breadcrumbs;
