import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from '../../stores/ImportStore';
import { RootState } from '../../stores/Store';
import BulkImport from './BulkImport';
import ImportHelper from './ImportHelper';

function Import() {
  const dispatch = useDispatch();
  const { journeyStep } = useSelector((state: RootState) => state.importReducer);

  useEffect(() => {
    dispatch(reset());
  }, []);

  /**
   * Render each step of import journey
   */
  const renderImportJourney = () => {
    switch (journeyStep) {
      case 1:
        return <ImportHelper />;
      case 2:
        return <BulkImport />;
      default:
        return null;
    }
  };

  return <>{renderImportJourney()}</>;
}

export default Import;
