import * as React from 'react';
import { SVGProps, memo } from 'react';

function ErrorExclamationTriangularFilledIcon(props?: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={19} viewBox="0 0 20 19" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.66081 1.65473L7.66761 1.64265C8.91924 -0.512022 11.1125 -0.549837 12.338 1.65051L19.489 14.789C20.7246 16.9751 19.6292 19 17.1537 19H2.84621C0.374693 19 -0.724941 16.9728 0.507063 14.796L7.66081 1.65473ZM9.99996 5.00004C10.2677 5.00004 10.4863 5.21054 10.4993 5.47509L10.5 5.50004V12.5625C10.5 12.8387 10.2761 13.0625 9.99996 13.0625C9.73218 13.0625 9.51357 12.852 9.50057 12.5875L9.49996 12.5625V5.50004C9.49996 5.2239 9.72382 5.00004 9.99996 5.00004ZM9.99996 14.25C9.58574 14.25 9.24996 14.5858 9.24996 15C9.24996 15.4143 9.58574 15.75 9.99996 15.75C10.4142 15.75 10.75 15.4143 10.75 15C10.75 14.5858 10.4142 14.25 9.99996 14.25Z"
        fill="#BD0000"
      />
    </svg>
  );
}
export default memo(ErrorExclamationTriangularFilledIcon);
