import {
  Box,
  Table as T,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Order } from '../../models/CommonModels';
import ChevronDownIcon from '../Icons/ChevronDownIcon';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import TableEmptyState from './TableEmptyState';
import { Header, TableProps } from './TableModels';

function Table({
  headers,
  rows,
  onSort,
  defaultOrderBy,
  defaultOrder = 'asc',
  isLoading,
  emptyStateMessage,
}: TableProps) {
  const [order, setOrder] = useState<Order>(defaultOrder);
  const [orderBy, setOrderBy] = useState(defaultOrderBy);
  const [t] = useTranslation();
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (containerRef) {
      requestAnimationFrame(() => {
        (containerRef.current as HTMLDivElement).scrollLeft = scrollLeft;
      });
    }
  }, [rows]);

  /**
   * On sort click handle
   * @param headerCell
   * @param newOrder
   * @param isLastColumn
   */
  const onSortHandle = (headerCell: Header, newOrder: Order, isLastColumn: boolean) => {
    const valToScroll = containerRef.current?.scrollLeft ?? 0;
    setScrollLeft(isLastColumn ? valToScroll + 100 : valToScroll);
    onSort?.({ orderBy: headerCell.id, order: newOrder });
  };

  return (
    <TableContainer ref={containerRef}>
      <T>
        <TableHead>
          <TableRow>
            {headers.map((headerCell, index) => (
              <TableCell
                key={headerCell.id}
                sortDirection={orderBy === headerCell.id ? order : false}
                sx={{
                  padding: '0.5rem',
                  borderBottom: `1px solid ${theme.palette.monochrome.monoChrome10}`,
                  ...headerCell.sx,
                }}
              >
                {headerCell.isSortable ? (
                  <TableSortLabel
                    IconComponent={ChevronDownIcon}
                    active={orderBy === headerCell.id}
                    direction={orderBy === headerCell.id ? order : 'asc'}
                    onClick={() => {
                      if (onSort) {
                        const newOrder = order === 'asc' ? 'desc' : 'asc';
                        setOrder(newOrder);
                        setOrderBy(headerCell.id);
                        onSortHandle(headerCell, newOrder, index === headers.length - 1);
                      }
                    }}
                    sx={{
                      fontSize: '1.25rem',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '1.75rem',
                      '& svg': {
                        marginLeft: '0.5rem',
                      },
                    }}
                  >
                    {headerCell.label}
                  </TableSortLabel>
                ) : (
                  <Typography
                    sx={{
                      fontSize: '1.25rem',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      lineHeight: '1.75rem',
                      '& svg': {
                        marginLeft: '0.5rem',
                      },
                    }}
                  >
                    {headerCell.label}
                  </Typography>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              {...row}
              sx={{
                backgroundColor: row.hasErrors ? theme.palette.table.error : undefined,
              }}
            >
              {row.cells.map((cell, idx) => {
                const checkForError = cell.hasError ? `${theme.palette.state.error}!important` : 'inherit';
                return (
                  <TableCell
                    key={`${row.id}_${headers[idx].id}`}
                    sx={{
                      a: {
                        color: isLoading ? `${theme.palette.monochrome.monoChrome14}!important` : 'inherit',
                        textDecorationColor: isLoading
                          ? `${theme.palette.monochrome.monoChrome14}!important`
                          : 'inherit',
                      },
                      color: isLoading ? `${theme.palette.monochrome.monoChrome14}!important` : checkForError,
                      fontSize: '1.125rem',
                      fontStyle: 'normal',
                      lineHeight: '1.625rem',
                      height: '2.75rem',
                      padding: '0.5rem',
                      borderBottom: `1px solid ${
                        isLoading ? theme.palette.monochrome.monoChrome14 : theme.palette.monochrome.monoChrome10
                      }`,
                      ...(cell.isCheckbox && {
                        borderRight: `1px solid ${
                          isLoading ? theme.palette.monochrome.monoChrome14 : theme.palette.monochrome.monoChrome10
                        }`,
                        padding: '0 0.5rem',
                        textAlign: 'center',
                      }),
                    }}
                    {...cell}
                  >
                    {cell.value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
        {!rows.length && !isLoading ? <TableEmptyState message={emptyStateMessage} /> : null}
      </T>
      {isLoading && !rows.length ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <LoadingSpinner style={{ minHeight: '148px', maxWidth: '148px' }} />
            <Typography sx={{ fontSize: '1.75rem', lineHeight: '2rem', fontFamily: 'Vodafone Lt' }}>
              {t('common.table.loadingResults')}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </TableContainer>
  );
}
export default Table;
