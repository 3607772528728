import { AxiosPromise } from 'axios';
import Api from '../../configs/AxiosConfig';
import {
  ConfirmDuplicatedUsersRequest,
  ConfirmNewConnectionsRequest,
  UpdateBulkImportRowRequest,
  ImportRequest,
  ImportResponse,
  UpdateBulkImportRowResponse,
  BulkImportValidRowsRequest,
  BulkImportValidRowsResponse,
  ConfirmBulkImportRequest,
} from '../../models/ImportModels';

const ImportService = {
  api: {
    fetchFile: (fileName: string): AxiosPromise<string> =>
      Api.get(`/import/download-file/${fileName}`, {
        responseType: 'blob',
      }),
    uploadFile: (params: ImportRequest): AxiosPromise<ImportResponse> =>
      Api.post(`/import/upload-file`, params, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }),
    confirmNewConnectionsUpload: (req: ConfirmNewConnectionsRequest): AxiosPromise<unknown> =>
      Api.post(`/import/upload-file/confirm`, req),
    confirmDuplicatedUsersUpload: (req: ConfirmDuplicatedUsersRequest): AxiosPromise<unknown> =>
      Api.post(`/import/upload-file/confirm`, req),
    updateBulkImportRow: (req: UpdateBulkImportRowRequest): AxiosPromise<UpdateBulkImportRowResponse> =>
      Api.post(`/import/update-row`, req),
    fetchBulkImportValidRows: (req: BulkImportValidRowsRequest): AxiosPromise<BulkImportValidRowsResponse> =>
      Api.post(`/import/valid-rows`, req),
    confirmBulkImportUpload: (req: ConfirmBulkImportRequest): AxiosPromise<unknown> =>
      Api.post(`/import/upload-file/confirm`, req),
  },
};

export default ImportService;
