import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { UploadType } from '../../../models/ImportModels';
import ImportService from '../../../services/Import/ImportService';
import { RequestParams } from '../../../stores/ImportStore';

function useImportSummaryStep() {
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [t] = useTranslation();

  /**
   * Confirms the import of a new connections import
   * @param uploadType
   * @param uploadId
   * @param request
   * @param onClose
   */
  const confirmImportNewConnections = (
    uploadType: UploadType,
    uploadId: string,
    request: RequestParams,
    onClose: () => void,
  ) => {
    setIsLoading(true);
    ImportService.api
      .confirmNewConnectionsUpload({
        uploadType: uploadType as UploadType,
        uploadId,
        ...request,
      })
      .then(
        () => {
          snackBar.showSnackBar(t('pages.import.uploadSuccess'), 'success');
          navigate('/esimmanager');
          onClose();
        },
        () => {
          snackBar.showSnackBar(t('pages.import.confirmNewConnectionsError'), 'error');
        },
      )
      .finally(() => setIsLoading(false));
  };

  return { confirmImportNewConnections, isLoading };
}

export default useImportSummaryStep;
