import { Box, RadioGroup, Skeleton } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Radio from '../../../../components/Radio/Radio';
import { AvailableNotificationsResponse } from '../../../../models/MarketsModels';
import { RootState } from '../../../../stores/Store';
import { updateImportRequest } from '../../../../stores/ImportStore';

interface BulkImportNotificationsStepProps {
  availableNotifications: AvailableNotificationsResponse | undefined;
  nextStep: () => void;
  onClose: () => void;
}
function BulkImportNotificationsStep({ availableNotifications, nextStep, onClose }: BulkImportNotificationsStepProps) {
  const [t] = useTranslation();
  const { notificationType } = useSelector((state: RootState) => state.importReducer.request);
  const dispatch = useDispatch();

  /**
   * Handles change of the radio button
   * @param e
   */
  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateImportRequest({ notificationType: e.target.value as 'PULL' | 'PUSH' }));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Box sx={{ paddingBottom: '2rem' }}>
        <RadioGroup
          aria-labelledby="notifications-radio"
          name="notifications-radio"
          value={notificationType}
          onChange={handleRadioChange}
        >
          {availableNotifications?.enablePull ? (
            <Radio value="PULL" label={t('pages.bulkImport.summaryDrawer.pullNotification')} />
          ) : null}
          {availableNotifications?.enablePush ? (
            <Radio value="PUSH" label={t('pages.bulkImport.summaryDrawer.pushNotification')} />
          ) : null}
          {!availableNotifications ? (
            <>
              <Skeleton width="100%" />
              <Skeleton width="100%" sx={{ marginTop: '2rem' }} />
            </>
          ) : null}
        </RadioGroup>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onClose} />
          <Button label={t('common.continue')} color="primaryButton" disabled={!notificationType} onClick={nextStep} />
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default BulkImportNotificationsStep;
