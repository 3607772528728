import { Grid, SelectChangeEvent } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import TextInput from '../../../../components/TextInput/TextInput';
import { DefaultCompanyCode } from '../../../../configs/PortalConfig';
import { EmailRegex, NameRegex } from '../../../../configs/RegexConfig';
import useAuth from '../../../../hooks/useAuth/useAuth';
import useAdminOnboarding from '../useAdminOnboarding';

function AdminOnboardingForm() {
  const [t] = useTranslation();
  const { id } = useParams();
  const [selectedMarket, setSelectedMarket] = useState<string | undefined>('');
  const {
    rolesOptions,
    companiesOptions,
    marketOptions,
    createAdmin,
    updateAdmin,
    isLoading,
    adminData,
    isCompanyInMarket,
    getCompanyByCompanyCode,
  } = useAdminOnboarding(id, selectedMarket);
  const navigate = useNavigate();
  const { userData } = useAuth();

  const formik = useFormik({
    initialValues: {
      firstName: adminData?.firstName || '',
      lastName: adminData?.lastName || '',
      email: adminData?.email || '',
      role: adminData?.role || '',
      companyCode: adminData?.company.code || '',
      marketId: adminData?.marketId || '',
      username: adminData?.username || '',
    },

    enableReinitialize: true,
    validationSchema: yup.object({
      firstName: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(
          NameRegex,
          t('common.inputValidations.validField', {
            field: t(`pages.eSimManager.newConnectionDrawer.firstName`).toLowerCase(),
          }),
        ),
      lastName: yup
        .string()
        .required(t('common.inputValidations.requiredField'))
        .matches(
          NameRegex,
          t('common.inputValidations.validField', {
            field: t(`pages.eSimManager.newConnectionDrawer.lastName`).toLowerCase(),
          }),
        ),
      email: yup
        .string()
        .matches(EmailRegex, t('common.inputValidations.email'))
        .required(t('common.inputValidations.requiredField')),
      role: yup.string().required(t('common.inputValidations.requiredField')),
      companyCode: yup.string().when('role', ([role]) => {
        if (role && !role.startsWith('VDF')) return yup.string().required(t('common.inputValidations.requiredField'));
        return yup.string();
      }),
      marketId: yup.string().when('role', ([role]) => {
        if (role && !role.startsWith('VDF')) return yup.string().required(t('common.inputValidations.requiredField'));
        return yup.string();
      }),
    }),
    onSubmit: (values) => {
      if (id && adminData) {
        updateAdmin({
          id: adminData.id,
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
          username: values.username,
        });
      } else {
        createAdmin({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
          companyCode: values.companyCode || DefaultCompanyCode,
          marketId: values.marketId || '',
          username: values.username,
          status: 'ACTIVE',
        });
      }
    },
  });

  /**
   * Handles company dropdown change
   * @param e
   */
  const handleMarketChange = (e: SelectChangeEvent<unknown>) => {
    formik.handleChange(e);
    setSelectedMarket(e.target.value as string);
    if (!!e.target.value && !isCompanyInMarket(e.target.value as string, formik.values.companyCode as string)) {
      formik.setFieldValue('companyCode', undefined);
    }
  };

  /**
   * Handles market dropdown change
   * @param e
   */
  const handleCompanyChange = (e: SelectChangeEvent<unknown>) => {
    formik.handleChange(e);
    if (!formik.values.marketId) {
      const company = getCompanyByCompanyCode(e.target.value as string);
      formik.setFieldValue('marketId', company?.marketId);
      setSelectedMarket(company?.marketId);
    }
  };

  return (
    <Grid container>
      <Grid item xl={4} md={6} xs={12} sx={{ paddingBottom: '2rem' }}>
        <form onSubmit={formik.handleSubmit} style={{ paddingTop: '1.5rem' }}>
          <TextInput
            autoComplete="off"
            key="firstName"
            id="firstName"
            name="firstName"
            type="text"
            label={t(`pages.adminOnboarding.firstName`)}
            value={formik.values.firstName}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            feedback={formik.touched.firstName && Boolean(formik.errors.firstName) ? formik.errors.firstName : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.adminOnboarding.firstName`)}
          />
          <TextInput
            autoComplete="off"
            key="lastName"
            id="lastName"
            name="lastName"
            type="text"
            label={t(`pages.adminOnboarding.lastName`)}
            value={formik.values.lastName}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            feedback={formik.touched.lastName && Boolean(formik.errors.lastName) ? formik.errors.lastName : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.adminOnboarding.lastName`)}
          />
          <TextInput
            autoComplete="off"
            key="email"
            id="email"
            name="email"
            type="text"
            label={t(`pages.adminOnboarding.email`)}
            value={formik.values.email}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            feedback={formik.touched.email && Boolean(formik.errors.email) ? formik.errors.email : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.adminOnboarding.email`)}
            disabled={!!id}
          />
          <Dropdown
            id="role"
            name="role"
            label={t('pages.adminOnboarding.role')}
            value={formik.values.role}
            onChange={(e) => {
              if (!id) {
                formik.setFieldValue('marketId', '');
                formik.setFieldValue('companyCode', '');
              }
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={formik.touched.role && Boolean(formik.errors.role)}
            feedback={formik.touched.role && Boolean(formik.errors.role) ? formik.errors.role : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            items={rolesOptions}
            disabled={adminData && userData?.email === adminData.email}
            placeholder={
              t('common.placeholders.dropdownPlaceholder') + t('pages.adminOnboarding.role').toLocaleLowerCase()
            }
          />
          {formik.values.role && !formik.values.role.startsWith('VDF') ? (
            <>
              {/* Market */}
              <Dropdown
                isPlaceholderDisabled={false}
                id="marketId"
                name="marketId"
                label={t('pages.adminOnboarding.market')}
                value={formik.values.marketId}
                onChange={(e) => {
                  handleMarketChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.marketId && Boolean(formik.errors.marketId)}
                feedback={formik.touched.marketId && Boolean(formik.errors.marketId) ? formik.errors.marketId : ''}
                formStyle={{ paddingBottom: '1.5rem' }}
                items={marketOptions}
                disabled={!!id}
                placeholder={
                  t('common.placeholders.dropdownPlaceholder') + t('pages.adminOnboarding.market').toLowerCase()
                }
              />
              {/* Company */}
              <Dropdown
                isPlaceholderDisabled={false}
                id="companyCode"
                name="companyCode"
                label={t('pages.adminOnboarding.company')}
                value={formik.values.companyCode}
                onChange={(e) => {
                  handleCompanyChange(e);
                }}
                onBlur={formik.handleBlur}
                error={formik.touched.companyCode && Boolean(formik.errors.companyCode)}
                feedback={
                  formik.touched.companyCode && Boolean(formik.errors.companyCode) ? formik.errors.companyCode : ''
                }
                formStyle={{ paddingBottom: '1.5rem' }}
                items={companiesOptions}
                disabled={!!id}
                placeholder={
                  t('common.placeholders.dropdownPlaceholder') + t('pages.adminOnboarding.company').toLowerCase()
                }
              />
            </>
          ) : null}

          <TextInput
            autoComplete="off"
            key="username"
            id="username"
            name="username"
            type="text"
            label={t(`pages.adminOnboarding.username`)}
            value={formik.values.username}
            onInputChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.username && Boolean(formik.errors.username)}
            feedback={formik.touched.username && Boolean(formik.errors.username) ? formik.errors.username : ''}
            formStyle={{ paddingBottom: '1.5rem' }}
            placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.adminOnboarding.username`)}
          />
          <ButtonGroup sx={{ paddingTop: '0.5rem' }}>
            {!id ? (
              <Button
                label={t('pages.uemOnboarding.submit')}
                color="primaryButton"
                type="submit"
                disabled={isLoading || !formik.isValid}
              />
            ) : (
              <>
                <Button
                  label={t('common.cancel')}
                  color="alternative2Button"
                  type="button"
                  onClick={() => navigate('/admins')}
                  disabled={isLoading}
                />
                <Button
                  label={t('common.saveChanges')}
                  color="primaryButton"
                  type="submit"
                  disabled={isLoading || !formik.isValid}
                />
              </>
            )}
          </ButtonGroup>
        </form>
      </Grid>
    </Grid>
  );
}

export default AdminOnboardingForm;
