import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkImportError } from '../../../../models/ImportModels';
import BulkImportEditDrawer from './BulkImportEditDrawer';
import ClickableLinkWithIcon from '../../../../components/Text/ClickableLinkWithIcon';

interface BulkImportDetailsRowEditProps {
  details: BulkImportError;
}

function BulkImportDetailsRowEdit({ details }: BulkImportDetailsRowEditProps) {
  const [t] = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <ClickableLinkWithIcon sx={{ textDecoration: 'underline' }} onClick={() => setOpenDrawer(true)}>
        {t('pages.bulkImport.table.editTable.cells.edit')}
      </ClickableLinkWithIcon>
      {openDrawer ? (
        <BulkImportEditDrawer details={details} open={openDrawer} onClose={() => setOpenDrawer(false)} />
      ) : null}
    </>
  );
}

export default BulkImportDetailsRowEdit;
