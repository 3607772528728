import { Box, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Table from '../../../components/Table/Table';
import SectionTitle from '../../../components/Text/TextStyles';
import Button from '../../../components/Button/Button';
import ErrorExclamationTriangularFilledIcon from '../../../components/Icons/ErrorExclamationTriangularFilledIcon';
import useBulkImportEditRows from './useBulkImportEditRows';

function BulkImportEditRows() {
  const [t] = useTranslation();
  const theme = useTheme();
  const { headers, rows, showErrorsExcludedModal } = useBulkImportEditRows();

  return (
    <>
      <SectionTitle sx={{ paddingTop: '1.5rem' }}>{t('pages.bulkImport.errorsDetected')}</SectionTitle>
      <Typography variant="body1" paddingBottom="2rem" lineHeight="normal">
        {t('pages.bulkImport.errorsHelper')}
      </Typography>
      <Table headers={headers} rows={rows} />
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '2.5rem', paddingBottom: '3rem' }}>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <ErrorExclamationTriangularFilledIcon />
          <Typography variant="body1" sx={{ color: theme.palette.state.error }}>
            {t('pages.bulkImport.warning')}
          </Typography>
        </Box>
        <Button sx={{ marginLeft: 'auto' }} onClick={showErrorsExcludedModal} label={t('common.proceed')} />
      </Box>
    </>
  );
}

export default BulkImportEditRows;
