import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useModal from '../../../hooks/useModal/useModal';
import useSnackBar from '../../../hooks/useSnackBar/useSnackBar';
import { NotificationSetting } from '../../../models/CompanyOnboardingModels';
import { UploadErrorCode, UploadType } from '../../../models/ImportModels';
import CompanyOnboardingService from '../../../services/CompanyOnboarding/CompanyOnboardingService';
import ImportService from '../../../services/Import/ImportService';
import {
  setCompanyId,
  setImportResponse,
  setNotificationSettings,
  setStep,
  setUploadErrorType,
  setJourneyStep,
  setBulkImportErrors,
  setBulkImportStep,
} from '../../../stores/ImportStore';

function useImportFileStep() {
  const dispatch = useDispatch();
  const snackBar = useSnackBar();
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const modal = useModal();

  /**
   * Retrieves only the types of notification that are enabled
   * @param notifications
   */
  const filterEnabledQrCodeNotifications = (notifications: NotificationSetting[]): NotificationSetting[] =>
    notifications.filter((notification) => notification.enabled);

  /**
   * Fetches the list of notification settings of the company
   * @param companyId
   */
  const getNotificationSettings = (companyId: string) => {
    setIsLoading(true);
    CompanyOnboardingService.api
      .fetchCompanyNotificationSettings(companyId)
      .then(
        (res) => {
          dispatch(setNotificationSettings(filterEnabledQrCodeNotifications(res.data.qrCodeNotifications)));
        },
        () => {
          snackBar.showSnackBar(
            t('pages.eSimManager.newConnectionDrawer.fetchCompanyNotificationsDefaultError'),
            'error',
          );
        },
      )
      .finally(() => setIsLoading(false));
  };

  /**
   * Requests the confirmation of the duplicated users import
   * @param uploadId
   * @param confirm
   * @param setOpen
   */
  const confirmDuplicatedUsersImport = (
    uploadId: string,
    confirm: boolean,
    setOpen?: (value: ((prevState: boolean) => boolean) | boolean) => void,
  ) => {
    modal.setIsLoading(true);
    ImportService.api
      .confirmDuplicatedUsersUpload({ uploadId, confirm, uploadType: UploadType.USERS })
      .then(
        () => {
          if (setOpen) {
            setOpen(false);
          }
          if (confirm) {
            snackBar.showSnackBar(t('pages.import.uploadSuccess'), 'success');
            navigate('/esimmanager');
          }
        },
        () => {
          snackBar.showSnackBar(t('pages.import.confirmUsersError'), 'error');
        },
      )
      .finally(() => modal.setIsLoading(false));
  };

  /**
   * Shows a modal to inform the user that there are duplicated users in the import
   * @param uploadId
   */
  const showDuplicatedUsersModal = (uploadId: string) => {
    modal.showModal({
      modalTitle: t('pages.import.importDrawer.duplicatedUsersTitle'),
      modalDescription: t('pages.import.importDrawer.duplicatedUsersMessage'),
      confirmationModal: true,
      confirmationButtonLabel: t('common.proceed'),
      cancelButtonLabel: t('common.cancel'),
      onAccept: (setOpen) => {
        confirmDuplicatedUsersImport(uploadId, true, setOpen);
      },
      onDiscard: () => {
        confirmDuplicatedUsersImport(uploadId, false);
      },
    });
  };

  /**
   * Uploads and handles the response of the csv file to check for issues
   * @param file
   * @param onClose
   */
  const uploadFile = (file: File, onClose: () => void) => {
    setIsLoading(true);
    dispatch(setImportResponse(null));
    ImportService.api
      .uploadFile({ file })
      .then(
        (res) => {
          dispatch(setUploadErrorType(null));
          if (res.data.errorLines && res.data.errorLines.length > 0) {
            dispatch(setImportResponse(res.data));
            dispatch(setStep(2));
          } else if (
            res.data.uploadType === UploadType.SIM_SWAPS ||
            res.data.uploadType === UploadType.NEW_CONNECTIONS
          ) {
            dispatch(setImportResponse(res.data));
            dispatch(setCompanyId(res.data.companyId));
            getNotificationSettings(res.data.companyId);
            if (res.data.hasErrors) {
              dispatch(setBulkImportErrors(res.data.errorsPage?.errors || []));
            } else {
              dispatch(setBulkImportStep(2));
            }
            dispatch(setJourneyStep(2));
          } else {
            snackBar.showSnackBar(t('pages.import.uploadSuccess'), 'success');
            onClose();
            navigate('/esimmanager');
          }
        },
        (err) => {
          if (err.response.data) {
            const code = UploadErrorCode[err.response.data.reason] || 'UNKNOWN_ERROR';
            dispatch(setUploadErrorType(code));
            if (code === 'UNKNOWN_ERROR') {
              snackBar.showSnackBar(t('pages.import.uploadError'), 'error');
            } else if (code === 'DUPLICATED_USERS') {
              showDuplicatedUsersModal(err.response.data.uploadId);
              onClose();
            }
          }
        },
      )
      .finally(() => setIsLoading(false));
  };

  return { getNotificationSettings, isLoading, uploadFile };
}

export default useImportFileStep;
