import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { RootState } from '../../stores/Store';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import PageTitle from '../../components/Text/PageTitle';
import ArrowLeft from '../../components/Icons/ArrowLeft';
import BulkImportEditRows from './BulkImport/BulkImportEditRows';
import BulkImportSelectRows from './BulkImport/BulkImportSelectRows';
import { reset, setBulkImportStep } from '../../stores/ImportStore';
import { UploadType } from '../../models/ImportModels';

function BulkImport() {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [pageTitle, setPageTitle] = useState(t('pages.bulkImport.title'));
  const { bulkImportStep, importResponse } = useSelector((state: RootState) => state.importReducer);

  /**
   * Handle the close action
   */
  const handleClose = () => {
    dispatch(reset());
  };

  /**
   * Handle the back button of the drawer to close or to go back a bulkImportStep
   */
  const handleBackButton = () => {
    if (bulkImportStep === 1 || (bulkImportStep === 2 && importResponse && !importResponse.hasErrors)) {
      handleClose();
    } else {
      dispatch(setBulkImportStep(bulkImportStep - 1));
    }
  };

  /**
   * Render each step of sim swap journey
   */
  const renderSteps = () => {
    switch (bulkImportStep) {
      case 1:
        return <BulkImportEditRows />;
      case 2:
        return <BulkImportSelectRows />;
      default:
        return null;
    }
  };

  useEffect(() => {
    switch (importResponse?.uploadType) {
      case UploadType.SIM_SWAPS:
        setPageTitle(t('pages.bulkImport.simSwap'));
        break;
      case UploadType.NEW_CONNECTIONS:
        setPageTitle(t('pages.bulkImport.newConnections'));
        break;
      default:
        setPageTitle(t('pages.bulkImport.title'));
        break;
    }
  }, [importResponse?.uploadType]);

  return (
    <Box>
      <Breadcrumbs
        activeLinks={[{ label: t('navbar.import'), url: `/import`, onClick: handleClose }]}
        disabledLinks={[pageTitle]}
      />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <ArrowLeft stroke={theme.palette.primary.main} style={{ cursor: 'pointer' }} onClick={handleBackButton} />
        <PageTitle sx={{ marginLeft: '1rem' }}>{pageTitle}</PageTitle>
      </Box>
      {renderSteps()}
    </Box>
  );
}

export default BulkImport;
