import { Box } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BulkImportError } from '../../../../models/ImportModels';
import Drawer from '../../../../components/Drawer/Drawer';
import BulkImportDetailsEditStep from './BulkImportDetailsEditStep';

interface BulkImportEditDrawerProps {
  details: BulkImportError;
  open: boolean;
  onClose: () => void;
}
function BulkImportEditDrawer({ details, open, onClose }: BulkImportEditDrawerProps) {
  /**
   * Handle the close action
   */
  const handleClose = () => {
    onClose();
  };
  const [t] = useTranslation();
  const [progress, setProgress] = useState(100);
  const [step, setStep] = useState(1);
  const NUMBER_OF_STEPS = 1;

  React.useEffect(() => {
    setProgress((step / NUMBER_OF_STEPS) * 100);
  }, [step]);

  /**
   * Handle the back button of the drawer to close or to go back a step
   */
  const handleBackButton = () => {
    if (step === 1) {
      handleClose();
    } else {
      setStep((prevState) => prevState - 1);
    }
  };

  /**
   * Render each step of sim swap edit details journey
   */
  const renderSteps = () => <BulkImportDetailsEditStep details={details} onFormClose={onClose} />;

  return (
    <Drawer
      disablePortal
      open={open}
      onClose={handleClose}
      onBackButton={handleBackButton}
      anchor="right"
      title={t('pages.bulkImport.editDrawer.title')}
      progress={progress}
    >
      <Box sx={{ paddingTop: '2rem', height: '100%' }}>{renderSteps()}</Box>
    </Drawer>
  );
}

export default BulkImportEditDrawer;
