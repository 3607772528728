import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLogger from '../../../../hooks/useLogger/useLogger';
import { RootState } from '../../../../stores/Store';
import { UpdateBulkImportRowRequest } from '../../../../models/ImportModels';
import ImportService from '../../../../services/Import/ImportService';
import { setBulkImportErrors } from '../../../../stores/ImportStore';

function useBulkImportDetailsEditStep() {
  const dispatch = useDispatch();
  const logger = useLogger();
  const [isLoading, setIsLoading] = useState(false);
  const { bulkImportErrors } = useSelector((state: RootState) => state.importReducer);

  /**
   * Updates a sim swap
   * @param params
   */
  const updateBulkImportRow = (params: UpdateBulkImportRowRequest, onClose: () => void) => {
    setIsLoading(true);
    ImportService.api
      .updateBulkImportRow(params)
      .then(
        (response) => {
          const indexToChange = bulkImportErrors.findIndex((error) => error.numberOfLine === params.numberOfLine);
          if (indexToChange !== -1) {
            const updatedErrors = [...bulkImportErrors];
            updatedErrors[indexToChange] = {
              ...updatedErrors[indexToChange],
              phoneNumber: response.data.phoneNumber,
              userEmail: response.data.userEmail,
              eid: response.data.eid,
              validRecord: response.data.validRecord,
            };
            dispatch(setBulkImportErrors(updatedErrors));
          }
        },
        () => {
          logger.logError('Bulk Import: Failed to update row');
        },
      )
      .finally(() => {
        setIsLoading(false);
        onClose();
      });
  };

  return { isLoading, updateBulkImportRow };
}

export default useBulkImportDetailsEditStep;
