import { Box, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import ButtonGroup from '../../../../components/ButtonGroup/ButtonGroup';
import TextInput from '../../../../components/TextInput/TextInput';
import SectionTitle from '../../../../components/Text/TextStyles';
import { BulkImportError, UploadType } from '../../../../models/ImportModels';
import useBulkImportDetailsEditStep from './useBulkImportDetailsEditStep';
import { RootState } from '../../../../stores/Store';

interface BulkImportDetailsEditStepProps {
  details: BulkImportError;
  onFormClose: () => void;
}

type DuplicateErrorType = 'DUPLICATED_EMAIL' | 'DUPLICATED_EID' | 'DUPLICATED_PHONE_NUMBER';

const DuplicateErrorCode: Record<number, DuplicateErrorType> = {
  3114: 'DUPLICATED_EMAIL',
  3116: 'DUPLICATED_EID',
  3132: 'DUPLICATED_PHONE_NUMBER',
};

function BulkImportDetailsEditStep({ details, onFormClose }: BulkImportDetailsEditStepProps) {
  const [t] = useTranslation();
  const { numberOfLine, phoneNumber, eid, userEmail, validRecord } = details;
  const { isLoading, updateBulkImportRow } = useBulkImportDetailsEditStep();
  const { importResponse } = useSelector((state: RootState) => state.importReducer);
  const formik = useFormik({
    initialValues: {
      phoneNumber: importResponse?.uploadType === UploadType.SIM_SWAPS && phoneNumber ? phoneNumber.value : '',
      eid: eid.value,
      userEmail: userEmail.value,
    },
    onSubmit: (values) => {
      if (importResponse) {
        updateBulkImportRow(
          {
            uploadId: importResponse.uploadId,
            uploadType: importResponse.uploadType,
            numberOfLine: details.numberOfLine,
            phoneNumber: values.phoneNumber,
            eid: values.eid,
            userEmail: values.userEmail,
          },
          onFormClose,
        );
      }
    },
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <SectionTitle>{t('pages.bulkImport.editDrawer.rowNumber', { n: numberOfLine })}</SectionTitle>
      <Box sx={{ paddingBottom: '2rem' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container columnGap="8.75rem">
            <Grid>
              {importResponse?.uploadType === UploadType.SIM_SWAPS && phoneNumber ? (
                <TextInput
                  autoComplete="off"
                  key="phoneNumber"
                  id="phoneNumber"
                  name="phoneNumber"
                  type="text"
                  label={t(`pages.bulkImport.editDrawer.mobileNumber`)}
                  value={formik.values.phoneNumber}
                  onInputChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={phoneNumber.error}
                  feedback={
                    phoneNumber.error && phoneNumber.reasons ? (
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        {phoneNumber.reasons.map((reason) => (
                          <Box key={`line-${reason}`}>
                            {t(`common.errors.httpReason.${reason}`)}
                            {DuplicateErrorCode[reason] === 'DUPLICATED_PHONE_NUMBER' && phoneNumber.duplicatedLines
                              ? t(`common.errors.duplicatedLines`, {
                                  l1: phoneNumber.duplicatedLines[0],
                                  l2: phoneNumber.duplicatedLines[1],
                                })
                              : null}
                          </Box>
                        ))}
                      </Box>
                    ) : (
                      ''
                    )
                  }
                  formStyle={{ paddingBottom: '1.5rem' }}
                  placeholder={
                    t('common.placeholders.inputPlaceholder') + t(`pages.bulkImport.editDrawer.mobileNumber`)
                  }
                  disabled={!phoneNumber.error}
                />
              ) : null}
              <TextInput
                autoComplete="off"
                key="userEmail"
                id="userEmail"
                name="userEmail"
                type="text"
                label={t(`pages.bulkImport.editDrawer.endUserEmail`)}
                value={formik.values.userEmail}
                onInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={userEmail.error}
                feedback={
                  userEmail.error && userEmail.reasons ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {userEmail.reasons.map((reason) => (
                        <Box key={`line-${reason}`}>
                          {t(`common.errors.httpReason.${reason}`)}
                          {DuplicateErrorCode[reason] === 'DUPLICATED_EMAIL' && phoneNumber.duplicatedLines
                            ? t(`common.errors.duplicatedLines`, {
                                l1: phoneNumber.duplicatedLines[0],
                                l2: phoneNumber.duplicatedLines[1],
                              })
                            : null}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    ''
                  )
                }
                formStyle={{ paddingBottom: '1.5rem' }}
                placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.bulkImport.editDrawer.endUserEmail`)}
                disabled={!userEmail.error}
              />
              <TextInput
                autoComplete="off"
                key="eid"
                id="eid"
                name="eid"
                type="text"
                label={t(`pages.bulkImport.editDrawer.deviceEid`)}
                value={formik.values.eid}
                onInputChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={eid.error}
                feedback={
                  eid.error && eid.reasons ? (
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      {eid.reasons.map((reason) => (
                        <Box key={`line-${reason}`}>
                          {t(`common.errors.httpReason.${reason}`)}
                          {DuplicateErrorCode[reason] === 'DUPLICATED_EID' && eid.duplicatedLines
                            ? t(`common.errors.duplicatedLines`, {
                                l1: eid.duplicatedLines[0],
                                l2: eid.duplicatedLines[1],
                              })
                            : null}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    ''
                  )
                }
                formStyle={{ paddingBottom: '1.5rem' }}
                placeholder={t('common.placeholders.inputPlaceholder') + t(`pages.bulkImport.editDrawer.deviceEid`)}
                disabled={!eid.error}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
      <Box sx={{ marginTop: 'auto' }}>
        <ButtonGroup>
          <Button label={t('common.cancel')} color="alternative2Button" onClick={onFormClose} />
          {!validRecord ? (
            <Button
              label={t('common.saveChanges')}
              color="primaryButton"
              disabled={isLoading}
              onClick={formik.submitForm}
            />
          ) : null}
        </ButtonGroup>
      </Box>
    </Box>
  );
}

export default BulkImportDetailsEditStep;
