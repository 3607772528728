import { Box, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useBulkImportSelectRows from './useBulkImportSelectRows';
import Table from '../../../components/Table/Table';
import TablePagination from '../../../components/TablePagination/TablePagination';
import Button from '../../../components/Button/Button';
import BulkImportDrawer from './components/BulkImportDrawer';
import { RootState } from '../../../stores/Store';

function BulkImportSelectRows() {
  const [t] = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { headers, rows, isLoading, totalItems } = useBulkImportSelectRows(activePage, pageSize);
  const { excludedImports } = useSelector((state: RootState) => state.importReducer);

  /**
   * On pagination change
   * Perform a new request
   * @param page
   * @param pSize
   */
  const handlePaginationChange = (page: number, pSize: number) => {
    setActivePage(page);
    setPageSize(pSize);
  };

  return (
    <Box sx={{ paddingTop: '1.5rem' }}>
      <Table headers={headers} rows={rows} isLoading={isLoading} />
      <TablePagination
        activePage={activePage}
        pageSize={pageSize}
        totalItems={totalItems}
        handlePageChange={handlePaginationChange}
      />
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '2.5rem', paddingBottom: '3rem' }}>
        <Typography variant="body1">
          {t('pages.bulkImport.numRowsSelected', { n: totalItems - excludedImports.length })}
        </Typography>
        <Button
          sx={{ marginLeft: 'auto' }}
          onClick={() => setOpenDrawer(true)}
          label={t('common.proceed')}
          disabled={isLoading}
        />
      </Box>
      {openDrawer ? (
        <BulkImportDrawer
          selectedBulkImports={totalItems - excludedImports.length}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
        />
      ) : null}
    </Box>
  );
}

export default BulkImportSelectRows;
